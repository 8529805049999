.ist-add-button {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  font-weight: $fuze-font-weight-regular !important;
  text-transform: capitalize !important;
  color: currentColor !important;
  letter-spacing: inherit !important;
  &:hover {
    color: $teal !important;
  }

  .icon-plus {
    color: $teal;
    border: 1px solid currentColor;
    border-radius: 50%;
    bottom: initial;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $fuze-font-size-regular;
    margin-right: 0.5rem;
  }
}

.ist-details__wrapper {
  display: inline-flex;
  max-width: 15.938rem; // 255px
  width: 100%;
  margin: 0 0.625rem 0.625rem 0;

  .ist-details__wrapper {
    flex: 0 0 auto;
  }

  .image-row {
    width: 100%;
  }
}

.ist-details__avatar {
  flex: 0 0 auto;
}

.ist-details > * {
  max-width: 14ch;
}

.ist-details__name {
  font-size: $fuze-font-size-base;
  font-weight: $fuze-font-weight-regular;
}

.ist-details__contact,
.ist-details__device {
  margin: 0 !important;
  color: $gray-50;
  font-size: $fuze-font-size-base;
}

.ist-details__buttons {
  flex: 0;
  height: 100%;
}

.emergency-contact_icon {
  font-size: 1rem;
  color: $gray-50;
  bottom: 0;
}

.emergency-contact__icon--edit {
  color: $fuze-teal-primary;
}

.danger-tooltip {
  background: $danger !important;
  color: white !important;
}

.danger-tooltip-arrow::before {
  border-right-color: $danger !important;
}

.emergency-email {
  margin-left: 0 !important;
}
