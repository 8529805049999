.auto-attendant-list-table {
  width: 100%;
}

.auto-attendant-list-table__cell {
  vertical-align: middle;
  width: 100%;
}

.auto-attendant-list-table__cell--name {
  width: 20%;
}

.auto-attendant-list-table__cell--modes {
  width: 60%;
}

.auto-attendant-list-table__cell--connections {
  width: 20%;
  max-width: 0;
  color: $gray-600;
}

.auto-attendant__name {
  font-weight: $font-weight-bold;
}

.auto-attendant__pbx {
  margin: 0;
  padding: 0;
  color: $gray-600;
}
.auto-attendant__connections {
  color: $gray-600;
  white-space: nowrap;
}

.auto-attendant__modes-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.auto-attendant__mode-item {
  margin: 0;
  margin-right: 0.5rem;
  padding: 0.2rem 0.4rem;
  border-radius: 0.5rem;
  white-space: nowrap;
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
}

.auto-attendant__mode-item__default {
  background-color: lighten(
    $color: $gray-100,
    $amount: 0
  ); //Attention: rather than using the lighten func, update to color code when available
  border: 1px solid $gray-200;
}

.auto-attendant__mode-item__auto {
  background-color: lighten(
    $color: $green-100,
    $amount: 68
  ); //Attention: rather than using the lighten func, update to color code when available
  border: 1px solid $green-100;
}

.auto-attendant__mode-item__manual {
  background-color: lighten(
    $color: $red-100,
    $amount: 60
  ); //Attention: rather than using the lighten func, update to color code when available
  border: 1px solid $red-100;
}

.auto-attendant__mode-item__icon {
  font-size: 0.8rem;
  margin-right: 0.3rem;
}
