.confirm-location {
  font-size: $fuze-font-size-regular;
}

.confirm-location__container {
  display: flex;
  justify-content: center;
}

.confirm-location__address {
  margin: 0 2em;
}

.confirm-location__button {
  appearance: none;
  border: none;
  background: none;
  display: inline;
  font-weight: $fuze-font-weight-light;
}
