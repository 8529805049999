.table-content--breakpoint-lg-and-up {
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.table__row--disabled {
  cursor: initial;
  opacity: 0.3;
}

.table thead th {
  vertical-align: middle;
}
