.event-cell__title-column {
  padding-left: 0;
  line-height: 1.125rem;
}

.event-cell__title {
  font-size: $fuze-font-size-h4;
  color: $gray-99;
  font-weight: $fuze-font-weight-light;
}

.event-cell__subtitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: $fuze-font-size-small;
  font-weight: $fuze-font-weight-regular;
  color: $gray-30;

  span + span {
    &:before {
      content: ", ";
    }
  }
}
