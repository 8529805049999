$header-navigation-z-index: 10;

// MIXINS
@mixin nav-link-underline() {
  content: "";
  background-color: white;
  bottom: 0;
  height: 2px;
  left: 10px;
  position: absolute;
  right: 10px;
}

// STYLE

header {
  background-color: $purple-dark;
  @include gradient-dark-purple-to-purple();
  color: white;
  flex: 0 0 $navbar-height;
  position: sticky;
  top: 0;
  z-index: 3;

  .navbar {
    padding: 0;
  }

  a:not(#skip-link) {
    color: inherit;
  }
  .navbar-brand {
    background: url("../../../images/logo.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: $navbar-height;
    margin-right: $spacer;
    margin-left: $spacer;
    padding: $navbar-height/4 0 0 45px;
    flex: 0 0 4.4rem;
    .brand-name {
      color: white;
    }
  }
  .navbar-toggler {
    border: 0 none;
    color: white;
  }
  .navbar-collapse {
    border-radius: 0 0 0.3rem 0.3rem;
    flex: 1;
    z-index: $header-navigation-z-index;

    .navbar-collapse {
      flex: 0 0 auto;
    }
  }
}

#header-left {
  .nav-link {
    font-size: 13px;
    height: 40px;
    letter-spacing: 1px;
    line-height: 48px;
    opacity: 0.7;
    padding: 0 10px;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    &:hover {
      opacity: 1;
      &::after {
        @include nav-link-underline();
      }
    }
    &.active {
      font-weight: 500;
      opacity: 1;
      &::after {
        @include nav-link-underline();
        opacity: 0.5;
      }
    }
  }
}

#header-right {
  .nav-item,
  .nav-item > a,
  .nav-item button {
    display: flex;
    align-items: center;
  }

  .fuze-avatar__overlay--initials {
    background-color: $purple-40;
  }

  @include media-breakpoint-up(md) {
    .nav-link {
      padding: 0;
    }
  }
  .member-name {
    font-size: 14px;
    font-weight: 500;
  }
  .dropdown {
    .arrow-down {
      display: inline-block;
      font-size: 0.8125rem;
      font-weight: $font-weight-bold;
      margin: 0 0 0 $spacer/2;
      position: relative;
      top: 0;
    }
    &.show {
      .arrow-down {
        transform: rotate(180deg);
      }
    }
  }
  .dropdown-menu {
    border: 1px solid $gray-20;
    box-shadow: 0 0 3px rgba($gray-20, 0.9);
    margin-top: $spacer;
    padding: 0;
    top: 2.5em;
    left: -0.5em !important;
    right: auto !important;
    @include media-breakpoint-down(sm) {
      left: 0.5em !important;
    }
    a,
    button {
      color: $gray-90;
      display: block;
      padding: 10px 20px;
      &:hover {
        text-decoration: none;
      }
    }
    .dropdown-title {
      color: $gray-20;
      font-size: 13px;
      letter-spacing: 0.2px;
      padding: 10px 10px 0 10px;
    }
    .member-details {
      font-size: 15px;
      list-style: none outside none;
      margin: 0;
      padding: 0;
    }
    .member-detail-user {
      color: $gray-60;
      padding: 1em;
      font-weight: inherit;
      .dropdown-item-text {
        display: flex;
        align-items: center;
        padding: 0;
        color: inherit;
      }
    }
    .member-details-role-locations {
      display: flex;
      flex-flow: column;
      color: $gray-800;
      line-height: 1.2rem;
      .member-name {
        font-size: $font-size-xs;
        font-weight: $font-weight-bolder;
      }
      .member-role {
        font-size: $font-size-sm;
        font-weight: 300; // $font-weight-light;
      }
      .member-managedLocations {
        color: $gray-500;
        font-size: $font-size-xxxs;
        font-weight: 300; // $font-weight-light;
        line-height: 1rem;
      }
      .member-managedLocations--additional {
        color: $gray-500;
        font-size: $font-size-xxxs;
        font-weight: 300; // $font-weight-light;
        line-height: 1rem;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .member-name {
      color: $gray-60;
      display: block;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      text-transform: none;
      white-space: nowrap;
    }
    .member-link {
      a,
      button {
        border-top: 1px solid $gray-5;
        display: block;
        cursor: pointer;
        padding: 12px 20px;
        background-color: inherit;
        border-left: 0 none;
        border-right: 0 none;
        border-bottom: 0 none;
        width: 100%;
        font-weight: inherit;
        text-align: left;
        line-height: inherit;
        &:hover {
          background-color: $gray-3;
        }
      }
    }
  }

  .nav-item {
    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: space-between;

      .top-nav-bar--user-help {
        background: none;
      }
    }
  }
}

.skip-link-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;
  position: absolute;
  top: -45px;
}

#skip-link {
  position: absolute;
  top: -45px;
  z-index: $header-navigation-z-index + 1; // header is z-index: 10; must be +1 to show on top of it
  transition: top 0.75s cubic-bezier(0.75, 0.25, 0.2, 1);
  margin-top: 2px;
  margin-bottom: 10px;

  &:focus {
    top: 45px;
    transition-duration: 0.5s;
    outline: 0;

    .btn {
      box-shadow: $btn-focus-box-shadow;
    }
  }

  &:hover,
  &:focus {
    .btn {
      border-color: $teal;
      color: $teal;
    }
  }
  &:active {
    .btn {
      border-color: $teal;
      color: $teal-dark;
    }
  }
}

#main-content {
  display: flex;
  flex-direction: column;
  // account for fixed header, prevent heading text from being covered
  padding-top: 40px;
  margin-top: -40px;
  max-width: 100%;
  z-index: 0;

  // prevent focus indicator from appearing on click of main content
  &:focus {
    outline: 0;
  }
}
