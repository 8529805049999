.statistics-header {
  .statistics-header__subtitle {
    font-size: $fuze-font-size-base;
    font-weight: $fuze-font-weight-light;
    margin-top: -2rem;
    margin-bottom: 2.8rem;
  }

  .statistic-header__link {
    color: white;
    transition: color 0.2s ease;
  }

  .statistic-header__amount {
    font-size: $fuze-font-size-statistic-title;
    font-weight: $fuze-font-weight-light;
    line-height: 3rem;
    animation: fade-in-updated-values 0.7s;
  }
}

.dashboard-statistics {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3.75rem;
  width: 100%;
}

.statistics__icon {
  $dimension: 2em;

  background-color: rgba(white, 0.25);
  border-radius: 50%;
  height: $dimension;
  font-size: $fuze-font-size-large;
  margin-right: 0.5em;
  padding: 0.25em;
  width: $dimension;
}

.statistic__title {
  font-size: $font-size-xxs;
  font-weight: $fuze-font-weight-medium;
  letter-spacing: 0.063rem;
  margin-bottom: 0;
  text-transform: uppercase;
}

@keyframes fade-in-updated-values {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.statistic-header--company {
  .statistic-header__link--new-user {
    color: white;
    height: 1.75rem;
    margin-left: 1ch;
    opacity: 0.75;
    transition: color 0.2s ease, border 0.2s ease, opacity 0.2s ease;
    &:hover {
      opacity: 1;
      color: $teal-light;
    }
  }
}
