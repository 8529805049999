.supportPopover {
  min-width: 500px;
}

.supportPopoverModal {
  min-width: 336px;
}

.supportPopoverBody {
  background-image: url("../../images/alert-icon.svg");
  background-repeat: no-repeat;
  background-position: 0.5rem 0.75rem;

  padding-top: 0.55rem;
  padding-left: 2.5rem;
  line-height: 18px;
}
