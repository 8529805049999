.fuze-page__header--with-close {
  $button-size: 3rem;

  align-items: center;
  display: grid;
  grid-template-columns: 1fr $button-size;
  padding: 1.5rem;
  padding-left: $button-size;
  text-align: center;

  h1 {
    font-weight: $fuze-font-weight-light;
    margin: 0;
  }
}
