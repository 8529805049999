.editable-file {
  display: flex;
  width: 100%;
}

.editable-file__img {
  align-self: flex-start;
}

.editable-file__description {
  width: 100%;
  margin-left: 1rem;
}

.editable-file__file-name {
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  margin-bottom: 0;
}
