@import "node_modules/@fuze/bootstrap-theme/scss/bootstrap-variables/colors";
@import "node_modules/@fuze/bootstrap-theme/scss/bootstrap-variables/fonts";
@import "../../styles/bootstrap_standin/boostrap_standin";

.deviceImage {
  max-width: 9.188rem;

  @include media-breakpoint-down(xs) {
    max-width: none;
  }
}

.productName {
  font-weight: $fuze-font-weight-regular;
  line-height: 1.75rem;
}

.deviceDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: $fuze-font-size-regular;
}

.orderDetails {
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.information {
  font-size: $fuze-font-size-regular;
  font-weight: $fuze-font-weight-regular;
}

.informationValue {
  font-weight: $fuze-font-weight-light;
}
