.contact-roster__details {
  text-transform: none !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  appearance: none;
  border: none;
  padding: 0.5em;
  border-radius: 5px;
  background: transparent !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  word-break: break-word;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
  &:hover {
    background: $gray-10 !important;
  }
  &:focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow !important;
  }
  &:disabled {
    color: initial;
  }

  [fuze-icon] {
    height: 1em;
  }
}

.contact-roster__details.contact-roster__name {
  font-size: $fuze-font-size-h1;
  font-weight: $fuze-font-weight-light;
  color: $gray-80;
  display: block;
}

.contact-roster__visibility {
  font-size: $fuze-font-size-h5 !important;
  color: $gray-40 !important;
  font-weight: $fuze-font-weight-light;
  span {
    color: $gray-80;
    font-weight: $fuze-font-weight-regular;
  }
}
