#top-nav-bar__user-dropdown {
  background-color: transparent;
  border-color: transparent;
  text-transform: initial;
}

.top-nav-bar {
  @include gradient-dark-purple-to-purple();

  display: flex;
  flex: 1;
}

.top-nav-bar--home {
  display: flex;
  justify-content: left;
  padding-left: 1rem;
}

.top-nav-bar--tenant-switcher {
  align-items: center;
  color: $fuze-gray-5;
  display: flex;
  flex: 0 0 auto;
  padding-left: 1rem;

  .dropdown {
    flex: 1;
  }

  @include media-breakpoint-down(sm) {
    padding: 1em;
  }
}

.tenant-switcher {
  align-items: center;
  display: flex;
  font-size: $fuze-font-size-base;
  padding: 8px;
  justify-content: center;
}

.tenant-switcher--button {
  color: $gray-050;
  background-color: $purple-extra-dark;
  font-weight: $fuze-font-weight-default;
  text-transform: initial;
  border: 1px solid $purple-extra-dark;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  &:hover {
    background-color: $fuze-purple-primary;
    border: 1px solid $fuze-purple-primary;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.tenant-switcher--button__open {
  background-color: $fuze-purple-primary;
  border: 1px solid $fuze-purple-primary;
}

.tenant-switcher--dropdown-menu {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 35rem;
  }
}

.tenant-switcher--dropdown-menu--filter {
  margin: 1rem 0;
  padding: 1rem;
  border-bottom: 1px solid $gray-10;
}

.tenant-switcher--dropdown-menu--filter--title {
  color: $gray-50;
  font-size: $fuze-font-size-base;
  font-weight: $fuze-font-weight-medium;
  text-transform: uppercase;
}

.tenant-switcher__icon {
  bottom: initial;
  font-size: $fuze-font-size-xsmall;
  padding-left: 0.5rem;
}

.top-nav-bar--links {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: 0;
}

.top-nav-bar--user-help {
  background: $purple-dark;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2ch;
}

.top-nav-bar--user-help--icon {
  color: #cdbde8;
  height: 1.75em;
}

.tenant-switcher--dropdown-menu--section {
  max-height: 30rem;
  overflow: auto;
  padding: 0;
}

.tenant-switcher__no-match {
  font-size: $fuze-font-size-h4;
  font-weight: $font-weight-normal;
  color: $gray-990;
  padding: 0.5rem;
}
