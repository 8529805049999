.pbx__help-tooltip {
  margin-left: auto;
  margin-right: 1.5em;
  font-size: $fuze-font-size-small;
  order: 4;
  text-transform: none;
  font-weight: 400;
  letter-spacing: initial;
  appearance: none;
  border: none;
  color: $teal;
  text-decoration: underline;
  background: none;
  &:hover {
    color: $teal-dark;
  }
}

.pbx__help-tooltip--body {
  text-align: center;
  font-size: $fuze-font-size-xsmall;
  padding: 20px;
  ul {
    padding: 0;
    list-style: decimal;
    list-style-position: inside;
    text-align: left;
  }
  li {
    line-height: 1.4;
  }
}

.pbx__help-tooltip--title {
  font-size: $fuze-font-size-small;
}

.pbx__not-sure-warning,
.pbx__locations {
  font-size: $fuze-font-size-regular;
  text-align: left;
  margin-bottom: 1em;
  width: calc(100% - 22px);
}

.pbx__more-locations .tooltip-inner {
  max-width: 20em;
  text-align: left;
  padding-right: 0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.pbx__more-locations__tooltip {
  max-height: 20em;
  overflow-y: auto;
}

#pbx__more-locations {
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  text-decoration: underline;
  color: inherit;
}

#send-to-fuze path:first-child {
  fill: $teal;
  stroke: $teal;
}

.icon__emergency-services {
  color: $danger;
  &:after {
    content: "";
    display: block;
    position: relative;
    margin: -0.25em auto auto;
    width: 30px;
    height: 18px;
    background: $gray-10;
    border-radius: 50%;
    z-index: -1;
  }
}
