@import "node_modules/@fuze/bootstrap-theme/scss/bootstrap-variables/bootstrap-variables";
@import "../../styles/bootstrap_standin/boostrap_standin";

.deactivatedUserCell {
  position: relative;
}

.reassignedIcon {
  display: block;
  position: absolute;
  height: 1.75rem;
  width: 1.75rem;
  top: calc(50% - 0.875rem);
  left: calc(100% - 0.063rem);
  background-color: white;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.servicesList {
  font-size: $fuze-font-size-small;
}

.servicesListGrey {
  color: $gray-30;
  font-style: italic;
}

.additionalServices {
  font-weight: 500; // $font-weight-md
}

.newUserCell {
  @include media-breakpoint-up(lg) {
    padding-left: 0.188rem;
  }
}

.mobileText {
  display: none;
  background-color: $gray-10;
  margin: 0 -0.75rem 1.063rem -0.75rem;
  padding: 0.625rem 0.75rem 0.563rem 0.75rem;
  font-weight: 500; // $font-weight-md

  @include media-breakpoint-down(md) {
    display: block;
  }
}
