$fuze-btn-icon-spacing: 0.2rem; // 8px

$fuze-btn-wide-width: 16.25rem; // "260px"

$fuze-btn-disabled-opacity: 0.3;

button {
  &.button--transparent {
    background: none;
    border: 0;
    padding: 0;
  }
}

.btn-round {
  border-radius: 100px;
  height: 40px;
  min-width: auto;
  overflow: hidden;
  padding: 10px;
  width: 40px;
}

.btn-wide {
  min-width: $fuze-btn-wide-width;
}

.btn-normal-spacing {
  letter-spacing: normal;
}

.unstyled {
  background: none;
  border: none;
  text-align: left;
}

.header-navigation-button {
  @include button--transparent();
  display: block;
  font-size: $fuze-font-size-base;
  color: $white;
  opacity: 0.7;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  &:hover {
    opacity: 1;
    background: $purple-extra-dark;
    border: 1px solid $purple-extra-dark;
    border-radius: 0.3rem;
  }
}
.header-navigation-button--active {
  display: block;
  font-size: $fuze-font-size-base;
  color: $white;
  opacity: 1;
  background-color: transparent;
  border: 1px solid $white;
  border-radius: 0.25rem;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
}

.copy-button {
  color: inherit;
}
