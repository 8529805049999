@import "node_modules/@fuze/bootstrap-theme/scss/bootstrap-variables/bootstrap-variables";
@import "../../styles/bootstrap_standin/boostrap_standin";

.companyDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subheading {
  font-size: $fuze-font-size-small;
  font-weight: $fuze-font-weight-regular;
  color: $gray-30;
}

.fileName {
  word-wrap: break-word;
}

.mobileText {
  display: none;
  background-color: $gray-10;
  margin: 0 -0.75rem 1.063rem -0.75rem;
  padding: 0.625rem 0.75rem 0.563rem 0.75rem;
  font-weight: 500; // $font-weight-md

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.avatarCol {
  max-width: 3.75rem;
  padding-right: 0;

  &.mobileHiddenCol {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
