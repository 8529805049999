//  TODO:
//  Bring this back into hub-style

// extend bootstrap button: "call to action" is wider
.btn-cta {
  padding-left: 3rem;
  padding-right: 3rem;
}

.btn-anchor {
  color: $link-color;
  display: inline;
  padding: initial;
  margin: initial;
  border: initial;
  text-transform: initial;
  letter-spacing: initial;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: initial;
  &:hover,
  &:focus {
    color: $link-hover-color;
  }
  &:active {
    color: darken($link-color, 10%);
  }
}

// extend bootstrap button: wrapping class gives multiple, inline buttons uniform spacing
.btns {
  // default left-aligned
  .btn {
    margin-right: $spacer;
  }

  &.btns-right {
    text-align: right;
    .btn {
      margin-left: $spacer;
      margin-right: 0;
    }
  }
}

.btn {
  display: inline-flex;
  justify-content: center;

  [fuze-icon]:where(+ span) {
    margin-right: 1ch;
  }
}

// normalize the distance of any dropdown menu from its toggle
.dropdown-menu.show[data-placement="top-start"] {
  top: -5px !important;
}

.dropdown-menu.show[data-placement="bottom-start"] {
  top: 5px !important;
}

.dropdown-item {
  align-items: center;
  display: flex;
  line-height: 0.938rem;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1rem;

  &:hover:not(:focus) {
    background-color: $gray-3;
  }

  &:active {
    color: initial;
    background-color: $gray-10;
  }

  &:disabled,
  &.disabled {
    .text-danger {
      opacity: 0.3;
    }
  }
}

// global styling for react-select 2.0.
// you must set <Select className="react-select" .../> for it to take effect.
.react-select {
  [class*="__control"] {
    background-color: white;
    border-color: $gray-30;
  }

  [class*="__control--is-focused"] {
    border-color: $teal-light;
    box-shadow: 0 0 1px rgba($teal-light, 0.5);

    &:hover {
      border-color: $teal-light;
    }

    [class*="__dropdown-indicator"] {
      color: rgba($teal-light, 0.5);

      &:hover {
        color: $teal-light;
      }
    }
  }
}

.react-select__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.react-select__disabled-message {
  font-size: $fuze-font-size-small;
  color: $gray-40;
}

@mixin popover-variant($background) {
  .popover {
    background: $background;
    border: 1px solid $background;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }

  .popover-body {
    color: color-yiq($background);

    max-width: 500px;
  }

  .bs-popover-auto > .arrow::after {
    border-top-color: $background;
  }

  a {
    color: color-yiq($background);
    text-decoration: underline;
  }
}

@each $color, $value in $theme-colors {
  .popover-#{$color} {
    @include popover-variant($value);
  }
}

.table__actions-row {
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin-top: -1rem;
  padding: 0.75rem 0;
  position: sticky;
  top: $navbar-height;
  z-index: 3;

  &.flex-wrap {
    flex-wrap: wrap;

    .table__actions--left,
    .table__actions--right {
      min-height: 3rem;
      min-width: 27.5rem;
    }
  }

  .table__actions--left,
  .table__actions--right {
    align-items: center;
    flex: 1;
  }

  .table__actions--left {
    a,
    button {
      @include media-breakpoint-up(xl) {
        margin-right: 0.375rem;
      }
    }
  }

  .table__actions--right {
    display: flex;
    justify-content: flex-end;
  }

  & + .react-bootstrap-table,
  & + * > .react-bootstrap-table,
  & + .react-table,
  & + * > .react-table {
    margin-top: 0.75rem;

    th {
      top: $navbar-height + 66px;

      .fuze-toggle-element {
        margin: 0;
      }
    }
  }
}

.react-bootstrap-table--static-header {
  thead th {
    position: static; // sometimes we don't want sticky positioning (ex. multiple tables on one page or in a modal)
  }
}

.table-td__more-menu {
  display: flex;
  justify-content: flex-end;
}

.pagination {
  [class*="page-"].disabled {
    display: none;
  }

  .page-link {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .page-spacer {
    cursor: default;
    pointer-events: none;
  }
}

.badge-pill {
  align-items: center;
  display: flex;
  height: 2em;
  justify-content: center;
  width: 2em;
}

.modal-content > form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}

.modal-footer {
  & > .buttons {
    display: inherit;
    flex: 1 1 auto;

    button {
      flex: 1 1 0;
    }
  }
}

.text--no-wrap {
  white-space: nowrap;
}

// TODO: Migrate to hub-style
table {
  .heading {
    color: $gray-990;
    font-weight: 400;
  }
  .details {
    font-weight: 0.8em;
    font-weight: 100;
  }
}

.footer {
  .fuze-toggle-element {
    margin-bottom: 1em;
  }
}

.panel__header_actions {
  button {
    color: inherit;
    display: flex;
    font-size: 1rem;
  }

  [fuze-icon] {
    height: 1em;
  }
}
