.audio-library-list-table__cell--name {
  width: 20%;
}

.audio-library-list-table__cell--preview {
  width: 40%;
  max-width: 0; // to fix stretching cell, see https://stackoverflow.com/a/11877033
}

.audio-library-list-table__cell--category-language {
  width: 20%;
}

.audio-library-list-table__cell--uses {
  width: 15%;
}

.audio-library-list-table__cell--actions {
  width: 5%;
}

.audio-library-list-table__cell {
  vertical-align: middle;
}
.audio-file__name {
  font-weight: $font-weight-bold;
}
.audio-file__details {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.audio-file__pbx {
  margin-right: 1ch;
}
.audio-category {
  padding-right: 0.5ch;
}

.upload-and-preview {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  justify-content: center;
  align-items: flex-start;
  grid-column-gap: 2rem;
  margin: 0rem;

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
  }
}

.upload-and-preview__uploaded-file {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.upload-and-preview__remove-file-button {
  border: none;
  background-color: transparent;
  margin-top: 0.375rem;
  align-self: flex-start;
}

.upload-and-preview__audio-preview {
  width: 100%;
  margin-bottom: 1rem;
}
