.pill-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-top: 0.15rem;

  .pill {
    margin-right: 0.25rem;
  }
}

.pill {
  display: inline-flex;
  padding: 0.2rem 0.5rem;
  color: $fuze-gray-100;
  background-color: $fuze-gray-10;
  font-size: $font-size-xxs;
  border-radius: 0.625rem;
  vertical-align: middle;
  white-space: nowrap;
}
