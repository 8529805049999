.drag-drop-file {
  padding: 1rem;
}
.drag-drop-file__drop-area {
  display: flex;
  padding: 0.5rem;
  border: 1px dashed $fuze-gray-40;
  border-radius: 4px;
}
.drag-drop-file__drop-area__icon {
  color: $fuze-teal-primary;
}

.edit-audio-file__file {
  .dropzone {
    height: 10rem;
    background-color: grey;
  }
}

.drag-drop-area__upload-button {
  text-transform: none;
  line-height: inherit;
}
.drag-drop-area__title {
  font-weight: $font-weight-normal;
  color: $gray-990;
  margin-bottom: 0;
}
.drag-drop-area__body {
  font-weight: $font-weight-light;
  font-size: $font-size-xs;
  color: $gray-700;
  margin-bottom: 0;
}

.edit-audio-file__remove {
  color: $gray-700;
  &:hover {
    color: $gray-990;
  }
}

.editable-audio-file {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 0.75rem;
  margin: 1rem;
  border: 1px solid $teal-60;
  border-radius: 3px;
}

.editable-audio-file__details {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0 0.5rem;
}
.editable-audio-file__details__file-data {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1rem;
}
.editable-audio-file__details__file-data__file-name {
  color: $gray-990;
}
.editable-audio-file__details__file-data__file-length {
  color: $gray-700;
}

.editable-audio-file__transcript {
  padding: 0 1rem 1rem 1rem;
}
.editable-audio-file__transcript-label {
  color: $gray-700;
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
}
.editable-audio-file__transcript-textarea {
  border: 1px solid $gray-200;
  border-radius: 4px;
  color: $gray-990;
}

.replaceable-audio-file__buttons-menu {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
}
.replaceable-audio-file__buttons-menu__cancel {
  color: $gray-700;
  font-weight: $font-weight-normal;
  &:hover {
    color: $gray-990;
  }
}
.replaceable-audio-file__buttons-menu__save {
  font-weight: $font-weight-normal;
}
