@import "node_modules/@fuze/bootstrap-theme/scss/bootstrap-variables/bootstrap-variables";
@import "../../styles/bootstrap_standin/boostrap_standin";

.orderTable {
  width: 100%;
  border: 1px solid $gray-10;
}

.th {
  width: 50%;
  padding: 0.625rem 0.75rem 0.563rem 0.75rem;
  font-weight: $fuze-font-weight-medium;
  background: $gray-10;
}

.td {
  padding: 1.063rem 0.75rem;

  ul {
    padding-left: 0;

    li {
      list-style-type: none;
      &:before {
        content: "\2022";
        padding-right: 0.375rem;
        font-weight: $fuze-font-weight-light;
      }
    }
  }
}

.dividerCell {
  border-right: 1px solid $gray-10;
}

.billingCell {
  text-align: right;
  color: $gray-850;
}

.userCell {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.footerMessage {
  text-align: right;
  color: $gray-30;
  font-style: italic;
  font-size: $font-size-xs;
  margin-top: 0.563rem;
  margin-right: 0.938rem;
}

.verticalTable {
  @include media-breakpoint-down(md) {
    display: block;
    width: 100%;

    thead {
      display: block;
      width: 100%;

      tr,
      td,
      th {
        display: block;
        width: 100%;
      }

      .mobileHiddenTh {
        display: none;
      }
    }

    tbody {
      display: block;
      width: 100%;

      tr,
      td,
      th {
        display: block;
        width: 100%;
      }

      .mobileHiddenTh {
        display: none;
      }
    }

    tfoot {
      display: block;
      width: 100%;

      tr,
      td {
        display: block;
        width: 100%;
        text-align: left;
      }
    }

    .dividerCell {
      border-right: 0 none;
    }

    .leftCell {
      padding-bottom: 0;
    }

    .rightCell,
    .billingCell {
      padding-top: 0;
    }
  }
}
