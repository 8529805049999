.activation-card__container {
  text-align: center;
  box-shadow: 0 0 10px 1px rgba($gray-500, 0.3);
  border-radius: 5px;
  margin: 0 auto 1.5rem;
  padding: 2rem 2.5rem;
  max-width: 525px;
  min-width: 375px;
}

.activation-card__title {
  color: $gray-900;
  font-size: $fuze-font-size-h2;
  font-weight: $fuze-font-weight-light;
  margin-bottom: 0.5rem;
}

.activation-card__width {
  max-width: 18.75rem;
  margin: auto;
}

.activation-card__code {
  @extend .activation-card__width;
  margin: 1rem auto;
  padding: 0.5rem 0;
  font-size: 50px;
  font-weight: $fuze-font-weight-light;
  @include custom-gradient();
  color: white;
  border-radius: 0.5rem;
  letter-spacing: 0.25rem;
  span:first-of-type {
    padding-right: 1rem;
  }
}

.activation-card__loading {
  span {
    padding-right: 0;
    width: 2.5rem;
    height: 2.5rem;
    border: 0.25rem solid rgba(white, 0.5);
    border-left-color: white;
  }
}

.activation-card__failed {
  box-shadow: 0 0 0 3px $fuze-red, inset 0 0 0 300px rgba(black, 0.5);
  transition: box-shadow 0.3s ease;
  img {
    vertical-align: initial;
  }
}

.activation-card__failed-message {
  font-weight: $fuze-font-weight-regular;
}

.activation-card__description {
  color: $gray-800;
  line-height: 1.4;
  font-size: 14px;
}

.activation-card__regenerate {
  align-items: center;
  border: none;
  color: $gray-500;
  display: inline-flex;
  font-size: $fuze-font-size-xsmall;
  margin-top: 1rem;
  padding: 1em;

  &:focus {
    outline: 0;
  }
  &:hover {
    color: inherit;
  }

  span {
    margin-left: 0.5em;
  }
}

.activation-card__mac-address {
  text-align: left;
  display: flex;
  align-items: center;
  margin: 2rem -2.5rem -1rem;
  padding: 1rem 2.5rem 0;
  border-top: 1px solid $gray-20;
  img {
    margin-right: 1rem;
    max-width: 5.625rem;
  }
  p {
    font-size: $fuze-font-size-regular;
  }
}
