@import "../styles/bootstrap_standin/_boostrap_standin.scss";

.maintenance {
  padding: 20px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: solid 4px $purple;

  &__contents {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 198px;
    margin-left: auto;
    margin-right: auto;
    max-width: 588px;
  }

  &__well {
    background-color: $gray-3;
    padding: 18px 28px;
  }

  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
    color: $gray-99;
  }

  &__message {
    color: $gray-70;
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
    padding: 0;
  }

  &__up-to-date {
    color: $gray-50;
    font-size: 12px;
    line-height: 18px;
    max-width: 244px;
    margin: 15px auto 14px auto;
    font-style: italic;
  }

  a {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1px;
  }
}
