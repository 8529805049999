.user-groups-list-table__cell--type {
  color: $fuze-purple-primary;
}

.user-group__type {
  padding-left: 1ch;
}

.user-group-members {
  display: flex;
  align-content: center;
  align-items: center;
}
.user-group-members__number {
  padding-right: 0.5rem;
}
.user-group-members__avatar.avatar {
  margin-right: 0.2rem;
}
.user-group-members__number,
.user-group-members__rest {
  color: $gray-600;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
}
