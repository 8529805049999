$chart-blue: $blue-80;

.section-block__troubleshooting-title {
  margin-top: 1.813rem;
}

.number-of-ratings {
  .pie-chart__center_top {
    color: $blue-80;
  }
}

.number-of-ratings-path {
  fill: $gray-100;
}
.number-of-ratings-path--rated {
  fill: $blue-80;
}

.most-reported-problems {
  path {
    fill: $blue-80;
  }

  .recharts-label {
    fill: $chart-blue;
    font-size: 1.25rem;
    font-weight: 600;
  }

  tspan {
    font-weight: 500;
  }
}

.data-below-threshold {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 18rem;
  justify-content: center;
  text-align: center;
}

.data-below-threshold__title {
  padding: 0 1rem;
  font-style: italic;
  font-weight: $fuze-font-weight-regular;
  color: $gray-70;
}

@import "./components/TopAtRiskUsers";
