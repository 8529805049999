.services-update__remove-service-review {
  display: flex;
  align-items: center;
  padding: 1em;
  border-radius: 4px;
}

.services-update-remove-service-review-image {
  margin-right: 2em;
}

.remove-service-review__statement2 {
  margin-top: 1em;
}
.remove-service-review {
  font-weight: $font-weight-bold;
}

.services-update__deactivate-panel {
  text-align: center;
  margin-top: 1rem;
}
.deactivateLink {
  text-decoration: underline;
}
