.api-key-eula {
  max-height: 31.25em;
  overflow: auto;
  header {
    font-size: $font-size-lg;
    text-align: center;
    margin-bottom: 1em;
  }
  ol {
    padding-left: 0;
  }
  li > p:first-child {
    display: inline-block;
  }
}
