.recording-config__description {
  font-weight: $fuze-font-weight-light;
}

.recording-config__label {
  font-weight: $fuze-font-weight-regular;
  display: inline-block;
  margin-right: 0.5em;
}

.recording-announcement {
  transform: translateX(-1rem);
  width: calc(100% + 2rem);

  .custom-control-label {
    line-height: $fuze-font-size-h3;
  }
}

.recording-announcement__text {
  display: block;
  text-align: left;
}

.recording-announcement__title {
  color: $gray-85;
  display: block;
}
