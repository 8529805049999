// This CSS Module does not have a corresponding .js file
// Instead, import these styles and use with an SVG
// Import the SVG as a component to apply the classes
// Example: import { ReactComponent as Svg } from "example.svg"
@import "node_modules/@fuze/bootstrap-theme/scss/bootstrap-variables/bootstrap-variables";

.small {
  // 24px
  height: 1.5rem;
  width: 1.5rem;
}

.medium {
  // 33px
  height: 2.063rem;
  width: 2.063rem;
}

.tall {
  // 30px H x 20px W
  height: 1.875rem;
  width: 1.25rem;
}

.greyCircleWrapper {
  background-color: $gray-5;
  padding: 0.625rem;
  border-radius: 50%;
  display: inline-block;
}

.buildingWrapper {
  composes: greyCircleWrapper;
  padding: 0.625rem 0.875rem 0.688rem 1rem;
}

.opaque path {
  opacity: 1;
}

.black path {
  fill: $gray-99;
}

.grey60 path {
  fill: $gray-60;
}

.purple path {
  fill: $purple-light;
}

.orange path {
  fill: $orange-light;
}

.green path {
  fill: $green;
}

.red path {
  fill: $red;
}

.teal path {
  fill: $teal;
}

.purpleGroup g {
  fill: $purple;
}

.whiteGroup g {
  fill: white;
}

.purpleGroupStroke g {
  stroke: $purple;
}
