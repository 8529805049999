.location-message {
  text-align: left;
  margin-top: 0.75rem;
}

.reset-message {
  @include gradient-purple-purple-to-blue();
  @include white-text;
  text-align: center;
  padding: 0.75rem 0.75rem 0.813rem 0.75rem;
  font-size: $fuze-font-size-small;
  line-height: $fuze-font-size-base;
}

.reset-message__button {
  background: transparent;
  color: white;
  border: 0 none;
  padding: 0 0 0 0.5ch;
  font-weight: inherit;
  text-decoration: underline;
}
