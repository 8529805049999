@import "./form-mixins";

.form-field {
  margin-bottom: 1rem;
}

.fuze-select__control {
  min-height: 40px;
}

.search-input-with-icon {
  width: 100%;

  [fuze-icon] {
    font-size: 1.6rem;
    position: absolute;
    padding: 0.6rem;
    z-index: 1;
  }

  input {
    font-family: $font-family-sans-serif;
    padding-left: 2.3rem;
    border-color: $gray-70;

    // css-tricks.com/almanac/selectors/p/placeholder/
    &::placeholder {
      color: $gray-40;
    }
  }
}

.fixed-width-field {
  max-width: $fixed-width-field-short;

  .modal-body & {
    max-width: 100%;
  }
}

.fixed-width-field--long {
  max-width: $fixed-width-field-long;
}

.spaced-label {
  strong {
    margin-right: 0.25em;
  }
}

.percent-input {
  background-color: inherit;
  &:after {
    content: "%";
    position: relative;
    margin-left: -2ch;
    z-index: 1;
    background: white;
    height: inherit;
    display: inline-flex;
    width: 2ch;
  }
}

.percent-input__box {
  border: none;
  border-bottom: 2px solid;
  display: inline-flex;
  padding: 0;
  position: relative;
  width: 5ch;
}

.readonly {
  .readonly__input,
  .readonly__input:active,
  .readonly__input:focus {
    background-color: $gray-5;
    border: $gray-5;
    color: $gray-990;
    padding-right: 2rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .readonly__input:focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }
  .readonly__icon-lock {
    position: absolute;
    left: calc(100% - 42px); // calc cannot use rems
    top: 2.25rem;
    background-color: $gray-5;
  }
}
