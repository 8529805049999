@mixin button--transparent {
  background: none;
  border: 0;
  padding: 0;
}

@mixin white-text {
  color: $white;
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: $white;
  }
}

@mixin custom-gradient($angle: 224.54deg) {
  background-color: $blue;
  background: linear-gradient(224.54deg, $blue-100 0%, $indigo-60 50.62%, $purple-60 100%);
}

@mixin interactive-table-row {
  background-color: transparent;
  box-shadow: 0 0.063rem 0.625rem 0 rgba($black, 0.1);
  cursor: pointer;

  td,
  + tr td {
    border-top: 0.063rem solid $gray-5;
  }
}

// Removes animations for users who have reduced motion enabled in their OS
@mixin optional-reduced-motion {
  @media (prefers-reduced-motion: reduce) {
    // !important is needed to override inline animation styles
    transition: none !important;
    animation: none !important;
  }
}
