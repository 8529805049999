.search {
  align-items: center;
  background-color: white;
  border: 1px solid $gray-80;
  border-radius: 4px;
  color: $gray-70;
  display: flex;
  height: 2.5rem;
  padding: 0 1ch;

  &:focus-within {
    border-color: $purple-80;
  }
  & ~ * {
    margin-left: 1ch;
  }
}

.search__icon {
  flex: 0 0 3ch;
  height: 1.25em;
}

.search__input {
  border: 0 !important;
  box-shadow: 0 !important;
  color: inherit;
  flex: 1;
  height: 100%;
  outline: 0 !important;

  &::placeholder {
    opacity: 0.5;
  }
}
.fuze-table-actions {
  @include apply-table-actions-responsive-rules() {
    .search {
      flex: 1;

      & ~ * {
        flex: 0 0 auto;
      }
    }
  }

  $actions-top: $navbar-height + 16px;

  background-color: white;
  margin: 0;
  padding-bottom: 1.5em;
  position: sticky;
  top: $actions-top;
  z-index: 1;

  & ~ .react-bootstrap-table th {
    top: $actions-top + 62px;
  }
}
