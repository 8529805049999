.toggle-section {
  margin: 1rem;
}

.toggle-section + .toggle-section {
  border-top: 1px solid $gray-10;
  margin-top: 1em;
  padding-top: 1em;
}

.toggle-section__button {
  justify-content: space-between;
  padding: 0;
  width: 100%;
}

.toggle-section__button__title {
  color: $gray-40;
  font-size: $fuze-font-size-small;
  font-weight: $font-weight-bold;
}
