.page-header__columns--two-columns {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.page-header__subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column:not(.column--shrink) {
  flex: 1;
}

.column--shrink {
  display: flex;
  flex: 1;
}

@media (max-width: 1199px) {
  .page-header__columns--two-columns {
    flex-direction: column;
  }
}

.fuze-page__inset--background-white {
  background-color: white;
}
