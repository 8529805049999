// increased specificity to overcome .btn defaults (ie box-shadow)
.tenant {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  box-shadow: none;
  &:hover {
    background-color: $gray-5;
    border-radius: 4px;
    cursor: pointer;
  }
}
.tenant__left {
  flex-direction: column;
  display: flex;
  flex: 1;
  align-items: flex-start;
  text-transform: initial;
}
.tenant__name {
  font-size: $fuze-font-size-h4;
  font-weight: $font-weight-normal;
  color: $gray-990;
}

.tenant__statuses {
  flex-direction: row;
}
.tenant__status {
  font-size: $fuze-font-size-xsmall;
  font-weight: $font-weight-light;
  color: $gray-60;
}

.favorite-tenant-button--active i {
  &::before {
    color: $yellow-80;
  }
}
