/*
 * Load order in this file is very important.
 * The more a selector or a variable is general, the higher
 * so that the CSS below it can inherit them and override them if needed
 *
 * ORDER:
 * 1. Libraries
 * 2. Project base
 * 3. UI Components
 * 4. Content Components
 * 5. Library overrides
 */

$fuze-avatar-display: inline-flex;

//  LIBRARIES
@import "react-datepicker/src/stylesheets/datepicker";
@import "react-toastify/scss/main";
@import "@fuze/icon-font/src/scss/icon-font";
@import "react-datepicker/dist/react-datepicker.css";

@import "./variables";
@import "./mixins";
@import "@fuze/fuzzy/scss/fuzzy";

//  PROJECT BASE
@import "./element";
@import "./utility";
@import "./form";
@import "./cursor";
@import "./fonts";
@import "./buttons";
@import "./table";
@import "./Icons";
@import "../components/layouts/BaseLayout";
@import "../components/layouts/components/ErrorCover";
@import "../components/layouts/components/HubSidebar";
@import "../components/Avatar-mixins";

//  UI COMPONENTS
@import "../components/Charts/Charts";
@import "../components/forms/Forms";
@import "../components/Image/Image";
@import "../components/Instructions";
@import "./fuzzy/Page";
@import "../components/Pill/Pills";
@import "../components/Tooltip/TooltipAnchor";

//  CONTENT COMPONENTS
@import "../components/layouts/components/Header";
@import "../components/CellWithHeader/CellWithHeader";

@import "../components/DIDSelector/DIDSelector";
@import "../users/templates/UserPageTemplate";
@import "../components/templates/ChartColumns";
@import "../components/templates/GradientSection";
@import "../components/support/SupportWarningBanner";
@import "../Utility/components/UtilityListModal";
@import "../Devices/components/ActivationCode";
@import "../components/Avatar";
@import "../components/Dropdown";
@import "../components/UserDetails";
@import "../components/forms/Select";
@import "../components/AuthenticationUnauthorized/AuthenticationUnauthorized";
@import "../components/Table/BillingTable";
@import "../components/Table/EventCell";
@import "../components/Table/tableFormatters";
@import "../components/DashboardActions/DashboardActions";
@import "../components/buttons/InfoButton";
@import "../Legal/components/TermsOfUse";
@import "../components/GrayBox";
@import "../components/Box/Box";
@import "../components/Box/BoxList";
@import "../components/Outline";
@import "../Integrations/ApiKeyManagement/components/LegalAgreement";
@import "../Locations/LocationsList/LocationsList";
@import "../Locations/Location/components/LocationGoogleMap";
@import "../Locations/Location/Location";
@import "../Locations/NewLocation/NewLocation";
@import "../Locations/NewLocation/components/VerifyLocation";
@import "../components/buttons/ActionWithBackButton.scss";
@import "../components/forms/FormFieldValidation.scss";
@import "../components/DashboardStatistics/DashboardStatisticsHeader";
@import "../components/accordion/accordion";
@import "../components/icon/icon";
@import "../components/HeaderFormatter";
@import "../Troubleshooting/Troubleshooting";
@import "../users/DeactivateReassign/components/WarningUserDeactivate";
@import "../users/new-user/components/AddNewUserBillingSummaryInstruction";
@import "../ContactRosters/ContactRosterDetails/components/ContactRosterContactsListTable";
@import "../ContactRosters/ContactRosterDetails/components/AddUsersToContactRosterModal";
@import "../ContactRosters/ContactRosterDetails/components/ContactRosterDetailsHeader";
@import "../Devices/DeviceBulkActivation/components/_DeviceBulkActivationFooter.scss";
@import "../CompanySettings/DesktopUpdates/components/SupportedVersions";
@import "../components/ErrorBadge/ErrorBadge";
@import "../components/layouts/TopNavBar/components/_TopNavBar";
@import "../CompanySettings/ActOnList/components/ActOnListTable";
@import "../components/DatePicker/DatePicker";
@import "../onboarding/_Onboarding.scss";
@import "../users/new-user/components/Plans";
@import "../users/UsersList/components/ContactRostersButton";
@import "../Orders/components/_OrdersEventFormatters";
@import "../components/Dropdown/Dropdown";
@import "../components/layouts/components/PageHeaderWithClose";

// MIGRATE USER PROFILE
@import "../users/NewUserProfile/UserProfile";
@import "../users/NewUserProfile/components/AdvancedFeaturesPanel/CallRecording/CallRecordingPlan";
@import "../users/NewUserProfile/components/AdvancedFeaturesPanel/CallRecording/RecordingConfigSelection";

@import "../Devices/Homepage/components/VerifyDeviceStatus";
@import "../users/users";
@import "../users/NewUserProfile/components/UserDetail";
@import "../users/NewUserProfile/components/UserActionsDropdown";
@import "../users/NewUserProfile/components/UserProfileHeader";

// LEGACY USER SERVICES UPDATE
@import "../users/legacyServiceUpdate/LegacyServiceUpdate";

//  LIBRARY OVERRIDES
@import "./hub-style";
@import "./icon-font/icon-font";
@import "./toast";

// BULK USER PROVISION
@import "../BulkProvision/BulkProvision";

// Alloy Migration
@import "../AlloyMigration/AlloyMigration";

// Troubleshooting
@import "../Troubleshooting/Troubleshooting";

// ContactCenter
@import "../ContactCenter/ContactCenter";

// Top navigation bar
@import "../components/layouts/TopNavBar/TopNavBar";

// CallFlowManager
@import "../CallFlowManager/AudioLibrary/AudioLibrariesTable";
@import "../CallFlowManager/AutoAttendant/AutoAttendantTable";
@import "../CallFlowManager/AudioLibrary/components/EditableFileInfo";
@import "../CallFlowManager/AudioLibrary/components/ReplaceableAudioFile";
@import "../CallFlowManager/AudioLibrary/components/ViewAudioFile";
@import "../CallFlowManager/AudioLibrary/components/EditSoundModal";
@import "../CallFlowManager/AudioLibrary/components/DeleteSoundModal";
@import "../CallFlowManager/AudioLibrary/components/BulkReplaceSoundModal";
@import "../CallFlowManager/AudioLibrary/components/EditableFileInfo";
@import "../CallFlowManager/UserGroups/UserGroups";
@import "../CallFlowManager/UserGroup/UserGroup";

// Things to axe start
// Things to axe end

[data-before-content]::before {
  content: attr(data-before-content);
  position: relative;
  z-index: 1;
}

[data-after-content]::after {
  content: attr(data-after-content);
  position: relative;
  z-index: 1;
}

.text-spacing {
  & > * {
    margin: 0.25rem 0;
    line-height: initial;
  }
}

.pagination {
  [class*="page-"].disabled {
    display: none;
  }

  .page-link {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .page-spacer {
    cursor: default;
    pointer-events: none;
  }
}

.table--with-borders {
  border: solid 1px $gray-10;
}

.gray-70 {
  color: $gray-70;
}

.react-tabs__tab {
  text-transform: uppercase;
}

.panel__list-item {
  i + i,
  i + span,
  i + strong,
  span + i,
  span + span,
  span + strong,
  strong + i,
  strong + span,
  strong + strong {
    margin-left: 1ch;
  }

  .second-line {
    display: block;
    color: $gray-70;
    line-height: $fuze-font-size-base;
    font-size: $fuze-font-size-small;
    margin-left: 0;

    span {
      margin-left: 0;
    }
  }
}
