@mixin location-icon-dot($dimensions: 20px, $margin-top: -15px) {
  content: "";
  display: block;
  background: $gray-20;
  border-radius: 50%;
  margin-left: auto;
  margin-bottom: auto;
  margin-right: auto;
  margin-top: #{$margin-top};
  width: $dimensions;
  height: $dimensions;
}

.icon-location__list {
  bottom: initial;
  font-size: 1.8em;
  margin-right: 0.5em;

  &:after {
    @include location-icon-dot(11px, -8px);
  }
}

.icon-location__list--is-registered,
.icon-location__avatar--is-registered i {
  color: $purple;
}

.icon-location__list--is-waived,
.icon-location__avatar--is-waived i {
  color: $gray-40;
}

.icon-location__list--not-registered,
.icon-location__avatar--not-registered i {
  color: $fuze-red;
}

.icon-location__list--is-registering,
.icon-location__avatar--is-registering i {
  color: $orange-light;
}

.icon-location__avatar {
  .fuze-avatar__overlay {
    background: rgba($gray-5, 0.5);
    box-shadow: 0 -2px 8px 0 rgba(43, 53, 64, 0.2);
    i:after {
      @include location-icon-dot(20px, -15px);
    }
    color: $purple-80;
  }
}

.icon-emergency-service {
  align-self: start;
}

.table td .subHeading.registration-status {
  color: $gray-70;

  &.not-required {
    font-weight: 300; // $font-weight-light;
  }
}

.locations-list-table {
  color: $gray-70;

  .name {
    color: $gray-99;
    font-size: $fuze-font-size-base;
  }
  td {
    width: 33%;
  }
  .location-list__actions {
    width: 1%;
  }
  .location-list__created {
    width: 20%;
  }
  .error {
    .form-field-validation__icon {
      margin: 0;
      width: 1em;
    }
  }
}

.registration-text__waived {
  color: $gray-50;
}
