.user-actions-dropdown__image {
  margin-bottom: 1rem;
}

.user-actions-dropdown__header {
  font-weight: $fuze-font-weight-medium;
  font-size: $fuze-font-size-base;
  color: $gray-90;
  margin-bottom: 0.375rem;
}

.user-actions-dropdown__body {
  margin-bottom: 0;
  line-height: initial;
  font-size: $fuze-font-size-base;
}

.user-actions-dropdown__dropdown--reset {
  width: 17rem;
}

.user-actions-dropdown__icon {
  bottom: 0;
}

.user-actions-dropdown__back-btn {
  display: flex;
  align-items: center;
}

.user-actions-dropdown__back-icon {
  font-size: $fuze-font-size-regular;
  padding-right: 0.625rem;
  bottom: initial;
}

.user-actions-dropdown__notification {
  margin-left: 0.25rem;
}
