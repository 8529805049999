.contact-roster__list-table_cell {
  vertical-align: middle;
}

.contact-roster__list-table__contact-name_header,
.contact-roster__list-table__contact-name_cell {
  width: 25%;
  padding-left: 0.625rem;

  @include media-breakpoint-down(md) {
    width: auto;
  }
}

.contact-roster__list-table__date-created_cell {
  @extend .contact-roster__list-table_cell;
}

.contact-roster__list-table__date-created_cell,
.contact-roster__list-table__date-created_header {
  @extend .contact-roster__list-table_cell;
  @include media-breakpoint-down(sm) {
    display: none;
  }
  text-align: center;
}

.contact-roster__list-table__action_cell {
  @extend .contact-roster__list-table_cell;
  text-align: right;
  padding: 0;
}

.contact-roster__list-table__contact-location_header,
.contact-roster__list-table__contact-location_cell {
  @extend .contact-roster__list-table_cell;
  @include media-breakpoint-down(lg) {
    display: none;
  }
  text-align: center;
}

.contact-roster__list-table__roster-name_header,
.contact-roster__list-table__roster-name_cell {
  width: 25%;
  padding-left: 0.625rem;

  @include media-breakpoint-down(md) {
    width: auto;
  }
}

.contact-roster__list-table__visibility_header,
.contact-roster__list-table__visibility_cell,
.contact-roster__list-table__number-of-users_header,
.contact-roster__list-table__number-of-users_cell {
  @extend .contact-roster__list-table_cell;
  text-align: center;
}

.remove-user-from-roster {
  border: none;
  background-color: transparent;
  i {
    font-size: $fuze-font-size-large;
    color: $gray-80;
  }
}
