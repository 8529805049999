$chart-grey: #eaebec; // TODO: Revisit this with Joao
$legend-right--width: 37ch;

.recharts-surface {
  position: absolute;
  z-index: 9;
}

.recharts-responsive-container {
  max-width: 100%;

  .recharts-wrapper,
  svg {
    bottom: 0;
    left: 0;
    max-width: inherit;
    right: 0;
    top: 0;
    z-index: 2;
  }

  path {
    transition: 0.3s stroke-width;
  }
}

.chart-legend {
  display: flex;
  justify-content: center;
  text-transform: capitalize;
  flex-wrap: wrap;
  padding: 0 1rem;

  .bar-chart--fuze & {
    justify-content: flex-start;
  }
}

.legend-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0.5rem;
  min-height: 36px;
}

.recharts-wrapper {
  $colors: (rating-0, $rating-color-0, $rating-border-color-0) (rating-1, $rating-color-1, $rating-border-color-1)
    (rating-2, $rating-color-2, $rating-border-color-2) (rating-3, $rating-color-3, $rating-border-color-3)
    (rating-4, $rating-color-4, $rating-border-color-4) (rating-ignored, $rating-ignored, $rating-border-ignored)
    (metric-all, $metric-color-all, $metric-border-color-all) (metric-0, $metric-color-0, $metric-border-color-0)
    (metric-1, $metric-color-1, $metric-border-color-1);

  @include populate-checkboxes(($colors));

  @include populate-chart-paths(($colors));
}

.recharts-legend-wrapper {
  display: flex;
  justify-content: center;
  z-index: 10;
}

.fuze-chart__legend--right {
  border: 3px solid white;
  border-left: 1px solid $chart-grey;
  display: flex;
  flex-direction: column;
  justify-items: center;
  height: 99%;
  margin: 1rem 0;
  padding: 0 2rem 0 0.5rem;

  .legend-item {
    min-height: 2.6em;
    width: 100%;

    label {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .fuze-toggle-element {
      margin: 0;
    }
  }

  .fuze-legend__numbers {
    align-items: center;
    display: flex;
    flex-direction: row;

    .percentage {
      font-size: 1.25rem;
      color: var(--checkbox-border-color);
      font-weight: 600;
      &:after {
        content: "%";
      }
    }
  }

  .fuze-legend__numbers--unchecked {
    margin-bottom: -0.5rem;
    &:before {
      content: "--";
      font-size: 2rem;
      line-height: 0;
      height: 0;
      position: relative;
    }
  }
}

.recharts-cartesian-grid {
  line {
    stroke: $chart-grey;
  }
}

.recharts-cartesian-axis-tick-value {
  font-size: $fuze-font-size-xsmall;
}

.chart-table {
  th {
    position: static;
    top: 0 !important;
  }
}

.pie-chart-relative-wrapper {
  height: 100%;
  position: relative;
  width: 100%;
}

.pie-chart--with-right-legend {
  & + .pie-chart__center {
    right: $legend-right--width;
  }
}

.pie-chart__center {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

.pie-chart__center_top {
  font-size: 2rem;
  font-weight: 600;
  line-height: 0.75;
  padding-top: 1rem;
}

.recharts-pie-sector path {
  stroke: white;
  stroke-width: 2px;
  transition: stroke 125ms;
}

.recharts-wrapper .recharts-bar path {
  stroke: white;
  stroke-width: 2px;
}

.list-item--issue .panel__inset {
  align-items: center;
  display: flex;

  .event-issue {
    flex: 1;
  }

  .list-item__numbers {
    flex: 0 auto;
  }

  .numbers--percentage {
    color: $fuze-purple-dark;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;

    &:after {
      content: "%";
    }
  }
}

// TOOLTIP
.recharts-tooltip-wrapper {
  z-index: 100;
  line-height: 1.5;
  width: 200px;
}

.custom-chart-tooltip {
  background-color: white;
  box-shadow: $fuze-card-box-shadow;
  padding: 0.5rem 1rem;
  z-index: 1;

  .tooltip__title {
    color: $gray-700;
    font-size: 1.15rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
    text-transform: uppercase;
  }

  .entry + .entry {
    margin-top: 1rem;
  }

  .entry {
    .entry__title {
      color: $gray-800;
      font-weight: 400;
    }
  }
}

.custom-chart-tooltip__rating-grid {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 5px auto;
  grid-template-areas:
    "bar title"
    "bar detail"
    "bar small";
  grid-column-gap: 0.5rem;
  min-width: 30ch;
}

.custom-chart-tooltip__bar {
  border-radius: 10px;
  grid-area: bar;
  width: 3px;

  .rating--veryBad &,
  .rating-0 & {
    background-color: $rating-color-0;
  }

  .rating--poor &,
  .rating-1 & {
    background-color: $rating-color-1;
  }

  .rating--fair &,
  .rating-2 & {
    background-color: $rating-color-2;
  }

  .rating--good &,
  .rating-3 & {
    background-color: $rating-color-3;
  }

  .rating--excellent &,
  .rating-4 & {
    background-color: $rating-color-4;
  }

  .rating--ignored &,
  .rating-ignored & {
    background-color: $rating-ignored;
  }

  .entry--all & {
    background-color: $metric-color-all;
  }

  .entry--calls & {
    background-color: $metric-color-1;
  }

  .entry--meetings & {
    background-color: $metric-color-0;
  }
}

.custom-chart-tooltip__title {
  align-items: center;
  display: flex;
  font-weight: 600;
  grid-area: title;

  .star-rating {
    line-height: 0;

    i {
      bottom: initial;
      font-size: 0.75rem;
      margin: 0 0.25rem;
    }
  }
}

.custom-chart-tooltip__detail {
  font-size: 1.25rem;
  font-weight: 600;
  grid-area: detail;
}
.custom-chart-tooltip__small-details {
  font-size: 0.75rem;
  grid-area: small;
  line-height: 1;
  margin-bottom: 0.25rem;
}

.bar-chart--fuze {
  .recharts-tooltip-cursor {
    display: none;
  }
}

.rating-percentage-bars {
  display: grid;
  grid-gap: 1px;
  height: 0.85rem;
  width: 100%;
}

.rating-percentage-bar {
  $border-radius: 4px;

  height: 100%;

  & + .rating-percentage-bar:before {
    background-color: white;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: 1px;
  }
}

.event-issue {
  align-items: center;
  display: flex;
}

.event-issue__icon {
  bottom: initial;
  color: $fuze-purple-dark;
  flex: 0;
}

.event-issue__category {
  margin: 0 1ch;
}

.event-issue__description {
  flex: 1;
}

.rating-percentage-bar--veryBad {
  background-color: $rating-color-0;
}
.rating-percentage-bar--poor {
  background-color: $rating-color-1;
}
.rating-percentage-bar--fair {
  background-color: $rating-color-2;
}
.rating-percentage-bar--good {
  background-color: $rating-color-3;
}
.rating-percentage-bar--excellent {
  background-color: $rating-color-4;
}
