.top-at-risk-user .panel__inset button {
  @include button--transparent;

  align-items: center;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 2ch auto auto 10ch;
  grid-template-areas: "rank user-information ratings rating-bars";
  width: 100%;
}

.top-at-risk-user__rank {
  color: $gray-400;
  font-size: 1.5rem;
  font-weight: 600;
  grid-area: rank;
}
.top-at-risk-user__user-information {
  grid-area: user-information;
}

.top-at-risk-user__ratings {
  font-size: $fuze-font-size-regular;
  grid-area: ratings;
  text-align: right;
  color: $gray-80;

  .count {
    font-size: $fuze-font-size-h2;
    font-weight: 600;
  }
}
.top-at-risk-user__rating-bars {
  grid-area: rating-bars;
}
