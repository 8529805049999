.view-audio-file {
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
}
.js-focus-visible .short-audio-player button:focus:not(.focus-visible) {
  box-shadow: none;
}

.short-audio-player__action {
  width: 40px;
  height: 40px;
  color: $white;
  background-color: $teal;
}

.view-audio-file__details {
  display: flex;
  width: 80%;
  flex-direction: column;
  padding: 0 0.5rem;
}
.view-audio-file__details__file-data {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.375rem;
  font-weight: $font-weight-bold;
  font-size: $font-size-lg;
}
.view-audio-file__details__file-data__file-name {
  color: $gray-990;
  text-overflow: ellipsis;
  overflow: hidden;
}
.view-audio-file__details__file-data__file-length {
  color: $gray-700;
  min-width: 7ch;
}
.view-audio-file__details__transcription {
  font-size: $font-size-xs;
  font-weight: $fuze-font-weight-medium;
  color: $gray-700;
}
.view-audio-file__details__transcription__message {
  font-style: oblique;
  font-weight: $fuze-font-weight-regular;
}

.view-audio-file--compact {
  padding: 0;

  .view-audio-file__details__file-data {
    justify-content: flex-start;
    padding-bottom: 0;
    font-size: $fuze-font-size-xsmall;
  }

  .view-audio-file__details__file-data__file-length {
    padding-left: 1ch;
    font-weight: $fuze-font-weight-default;
  }

  .view-audio-file__details__transcription {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
