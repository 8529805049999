.boxlist__content {
  padding: 1.4rem 1.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}

.boxlist__content--status-bar {
  border-left: 5px solid $gray-40;
  &.active {
    border-color: $purple;
  }
}

.boxlist__token-code {
  @include custom-gradient();
  display: inline-flex;
  font-size: $fuze-font-size-h2-sub;
  color: white;
  min-height: 56px;
  border-radius: 0.5rem;
  align-items: center;
  word-break: break-all;

  button {
    align-items: center;
    border-left: 1px solid rgba(white, 0.2) !important;
    border-radius: 0;
    color: white;
    display: flex;
    flex: 0 0 auto;
    font-size: 1em;
    justify-content: center;
    padding: 0 1ch;
  }
}

.boxlist__token-code__token {
  flex: 1;
  padding: 1rem 1.5rem;
}

.boxlist__badge {
  display: inline-flex;
  padding: 0.2rem 0.5rem;
  margin-left: 1em;
  color: $gray-90;
  background-color: rgba($gray-70, 0.3);
  font-size: 9px;
  font-weight: $fuze-font-weight-medium;
  border-radius: 1em;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  vertical-align: middle;
  &.active {
    background-color: rgba($purple, 0.3);
  }
}

.boxlist__title {
  margin-bottom: 0.25rem;
  font-weight: $fuze-font-weight-default;
  font-size: $font-size-xl;
  grid-column: 1 / 4;
  grid-row: 1;
}

.boxlist__list {
  grid-column: 1 / 4;
  grid-row: 2;
  padding: 0;
  list-style: none;
  display: flex;
  margin: 0;
}

.boxlist__list-item {
  font-size: $fuze-font-size-xsmall;
  display: inline-flex;
  align-items: center;
  + .boxlist__list-item:before {
    content: "";
    margin: 0 8px;
    width: 3px;
    height: 3px;
    background: $gray-80;
    display: block;
    border-radius: 50%;
  }
}

.boxlist__list-item--link {
  text-decoration: underline;
  color: $gray-80;
  border: none;
  padding: 0;
  background: none;
  &:hover {
    color: darken($gray-80, 20%);
  }
}

.boxlist__list-item--delete {
  padding: 0;
  border: none;
  background: none;
  font-weight: $fuze-font-weight-default;
  text-decoration: underline;
  color: $fuze-red;
  &:hover {
    color: darken($fuze-red, 20%);
  }
}

.boxlist__button {
  grid-column: 4;
  grid-row: 1 / 3;
  max-height: 40px;
}

.boxlist__add-button {
  display: block;
  width: 100%;
  border: 1px dashed $gray-20;
  background-color: white;
  color: $gray-70;
  text-transform: uppercase;
  padding: 0.8rem 1.1rem;
  text-align: left;
  font-size: $fuze-font-size-regular;
  font-weight: $fuze-font-weight-medium;
  letter-spacing: 1px;
  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    background: $gray-3;
    color: $gray-90;
  }
  &:focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }
  i {
    margin-right: 0.5em;
  }
}
