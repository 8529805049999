@import "node_modules/@fuze/bootstrap-theme/scss/bootstrap-variables/bootstrap-variables";
@import "../../styles/bootstrap_standin/boostrap_standin";

.information {
  font-size: 0.813rem;
  font-weight: 500; // $font-weight-md
}

.informationValue {
  font-weight: $fuze-font-weight-light;
}

.avatarCol {
  max-width: 3.75rem;
  padding-right: 0;

  &.mobileHiddenCol {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
