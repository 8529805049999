.contact-center-link-text {
  color: $gray-60;
  text-decoration: underline;
}

.help-center-text {
  font-weight: $fuze-font-weight-regular;
}

.help-center-icon {
  margin-right: 10px;
}

.fuze-time-picker__wrapper {
  display: flex;
  align-items: center;
}

.fuze-days-list {
  $toggle-spacing: 1em;
  display: flex;

  .fuze-toggle-element {
    margin: $toggle-spacing 0;

    & + .fuze-toggle-element {
      margin-left: $toggle-spacing;
    }
  }
}

.time-picker-dropdown {
  flex: 0 0 80%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.time-picker-dropdown > * {
  flex: 1;
}

.fuze-day-item {
  flex: 0 0 20%;
}

.search-agents {
  padding-bottom: 1rem;
  width: 95%;

  div[class*="singleValue"] {
    padding-left: 2rem;
  }

  div[class*="control"] {
    border: 1px solid $gray-50;
    padding: 4px 0 4px 0 !important;
  }
}
