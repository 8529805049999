.fuze-avatar--large {
  font-size: 3em;
  font-weight: 300;
}

%avatar__overlay--positioning {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.avatar {
  .fuze-avatar__overlay {
    font-weight: 500;
  }
  &:not(.fuze-avatar--small):not(.fuze-avatar--large) {
    .fuze-avatar__overlay {
      font-size: $font-size-xl;
    }
  }

  .fuze-avatar-label {
    line-height: 1.5;
    text-align: left;
  }

  &.inactive {
    .fuze-avatar__overlay {
      background-color: $gray-40;
    }
  }

  &:not(.avatar--with-name) {
    margin-right: 0.75em;
  }
}

.avatar--locked {
  .fuze-avatar__overlay:last-child {
    color: white;
    text-shadow: -1px 0px 4px rgba(0, 0, 0, 0.25), 1px 0px 4px rgba(0, 0, 0, 0.25);

    &:before {
      @include gradient-purple-purple-to-blue();
      @extend %avatar__overlay--positioning;
      content: "";
      opacity: 0.5;
    }

    i:before {
      color: white;
    }
  }
}

.avatar__icon {
  z-index: 1;
}

.user__additional-details {
  font-size: $fuze-font-size-small;
  font-weight: 400;
  color: $fuze-gray-70;
}

.avatar-with-name-container {
  .image-row__content {
    line-height: 1.25;
  }
}
