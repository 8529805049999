.bulk-activation__footer_title {
  font-size: $fuze-font-size-h3;
  color: $gray-99;
  font-weight: $fuze-font-weight-light;
}

.bulk-activation__footer_description {
  font-size: $fuze-font-size-base;
  color: $gray-80;
  line-height: 22px;
}

.bulk-activation__footer_action {
  margin-top: 1em;
}

.button {
  background: white;
}
