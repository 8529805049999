$color-shades: 20 40 60 80 100;

@each $color in $color-pallette {
  $color-list: map-get($color-pallette-map, $color);

  @each $shade in $color-shades {
    $color-value: map-get($color-list, $shade);

    .fuze-icon--#{$color}-#{$shade} {
      color: #{$color-value};
      fill: #{$color-value};
    }
  }
}

.icon--bordered {
  align-items: center;
  border: 1px solid;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  padding: 0.25em;
}

[fuze-icon] {
  &[class^="icon-"],
  &[class*=" icon-"] {
    bottom: initial;
  }
}
