#section-supported-versions {
  border-top: 1px solid $gray-10;
  h2 {
    margin-bottom: 25px;
  }
  h3 {
    margin-bottom: 20px;
  }
}

.version-container {
  border-bottom: 1px solid $gray-10;
  font-size: $font-size-sm;
  margin-bottom: 1px;
  padding: 1.25rem;
  &:last-child {
    border-bottom: 0 none;
  }
  .badge {
    color: white;
    font-size: $font-size-sm;
    font-weight: 400; // $font-weight-normal;
    padding: 0.5rem 0.7rem 0.4rem;
    position: relative;
    text-transform: uppercase;
    top: -0.45rem;
    left: 0.5rem;
  }
  .version-preview-image-wrapper {
    background: url("../../../images/placeholder_release_image.png") no-repeat center center transparent;
    background-size: cover;
  }
  .version-preview-image {
    // background-image: ... is set inline in the <div>
    background-image: url("../../../images/placeholder_release_image.png");
    background-color: rgba($gray-50, 0.2); // "placeholder" for missing thumbnails
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 2px 2px rgba(black, 0.2);

    // creates a box with the correct height,
    // assuming the image is 245w x 145h > 145/245 > 0.6%.
    padding-top: 60%;
  }
  .version-number-label {
    display: block;
    font-size: $font-size-sm;
    line-height: 1.5;
  }
  .version-number {
    line-height: 1;
    margin-bottom: 4px;
  }
  .version-date-label {
    font-size: 90%;
    opacity: 0.7;
  }
  .version-date {
    line-height: 1.6;
    white-space: nowrap;
    .btn#{$fuze-bootstrap-button-selector} {
      letter-spacing: 0;
      text-transform: capitalize;
    }
  }
  .buttons {
    margin-top: 1.5; // $paragraph-margin-bottom;
    .btn {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
  .version-links {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .version-link {
    border-bottom: 1px solid transparent;
    line-height: 1.2;
  }
  .version-na {
    opacity: 0.5;
  }
  .dropdown-menu {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    width: 14rem;
    a {
      display: inline-block;
      width: 50%;
    }
  }
  .dropdown-header {
    color: $gray-40;
    font-size: $font-size-xs;
    letter-spacing: 0.5px;
  }
  .dropdown-block {
    a {
      background-color: inherit;
    }
  }

  .alert-fuzebot {
    margin-bottom: 0;
  }

  &.gradient-purple-blue-to-teal {
    border-bottom: 1px solid $purple-extra-light;
    border-radius: 3px;
    margin-bottom: 0;
    .version-number-label {
      color: white;
    }
    .version-link {
      color: rgba(white, 0.8);
      border-bottom-color: rgba(white, 0.8);
    }
  }
}
