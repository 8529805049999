.tooltip-anchor {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: $fuze-font-weight-default;
  padding: 0;
  display: inline;
  &:active {
    color: $fuze-gray-100;
  }
}
