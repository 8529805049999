.connection__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.connection__item-name {
  font-weight: bold;
}

.connection__item-type {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $purple-80;
  width: 30%;
}

.connection__item-type-name {
  margin-left: 0.5rem;
}
