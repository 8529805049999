$fuze-more-menu-background-color: white !default;
$fuze-more-menu-dropdown-menu-border: 0 !default;
$fuze-more-menu-dropdown-menu-filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.25)) !default;
$fuze-more-menu-dropdown-menu-padding: 0 !default;
$fuze-more-menu-dropdown-menu-text-align: left !default;

$fuze-more-menu-dropdown-menu-border-top: 1px solid $gray-20 !default;

$fuze-more-menu-dropdown-menu--childre-font-size: inherit !default;
$fuze-more-menu-dropdown-menu--childre-font-weight: 300 !default;
$fuze-more-menu-dropdown-menu--childre-padding: 1rem !default;

$fuze-more-menu-border-width: 8px !default;

$fuze-more-menu-dropdown-arrow-z-index: 1 !default;

$fuze-more-menu-toggle-padding: 0.5em !important !default; // override Bootstrap
$fuze-more-menu-toggle-width: 3em !default;

$fuze-more-menu-toggle-display: flex !default;
$fuze-more-menu-toggle-align-items: center !default;
$fuze-more-menu-toggle-justify-content: center !default;

$fuze-more-menu-toggle-icon-bottom: initial !default; // TODO: Remove once icons are fixed

.fuze-more-menu__dropdown-menu {
  background-color: $fuze-more-menu-background-color;
  border: $fuze-more-menu-dropdown-menu-border;
  filter: $fuze-more-menu-dropdown-menu-filter;
  padding: $fuze-more-menu-dropdown-menu-padding;
  text-align: $fuze-more-menu-dropdown-menu-text-align;

  & > *:not(:first-child) {
    border-top: $fuze-more-menu-dropdown-menu-border-top;
  }

  a,
  button,
  > li {
    font-size: $fuze-more-menu-dropdown-menu--childre-font-size;
    font-weight: $fuze-more-menu-dropdown-menu--childre-font-weight;
    padding: $fuze-more-menu-dropdown-menu--childre-padding;
  }

  &:after {
    border-color: transparent;
    border-style: solid;
    border-width: $fuze-more-menu-border-width;
    content: "";
    position: absolute;
    z-index: $fuze-more-menu-dropdown-arrow-z-index;
  }

  &[x-placement="bottom-end"]:after {
    border-bottom-color: $fuze-more-menu-background-color;
    margin-top: -#{$fuze-more-menu-border-width};
    padding-top: 1px;
    right: 0;
    top: -#{$fuze-more-menu-border-width};
  }

  &[x-placement="bottom-start"]:after {
    border-bottom-color: $fuze-more-menu-background-color;
    left: 0;
    margin-top: -#{$fuze-more-menu-border-width};
    padding-top: 1px;
    top: -#{$fuze-more-menu-border-width};
  }

  &[x-placement="left-end"]:after {
    border-left-color: $fuze-more-menu-background-color;
    bottom: 0;
    margin-right: -#{$fuze-more-menu-border-width};
    padding-right: 1px;
    right: -#{$fuze-more-menu-border-width};
  }

  &[x-placement="left-start"]:after {
    border-left-color: $fuze-more-menu-background-color;
    margin-right: -#{$fuze-more-menu-border-width};
    padding-right: 1px;
    right: -#{$fuze-more-menu-border-width};
    top: 0;
  }

  &[x-placement="right-end"]:after {
    border-right-color: $fuze-more-menu-background-color;
    bottom: 0;
    left: -#{$fuze-more-menu-border-width};
    margin-left: -#{$fuze-more-menu-border-width};
    padding-left: 1px;
  }

  &[x-placement="right-start"]:after {
    border-right-color: $fuze-more-menu-background-color;
    left: -#{$fuze-more-menu-border-width};
    margin-left: -#{$fuze-more-menu-border-width};
    padding-left: 1px;
    top: 0;
  }

  &[x-placement="top-end"]:after {
    border-bottom-color: $fuze-more-menu-background-color;
    bottom: -#{$fuze-more-menu-border-width};
    margin-top: -#{$fuze-more-menu-border-width};
    padding-top: 1px;
    right: 0;
  }

  &[x-placement="top-start"]:after {
    border-bottom-color: $fuze-more-menu-background-color;
    bottom: -#{$fuze-more-menu-border-width};
    left: 0;
    margin-top: -#{$fuze-more-menu-border-width};
    padding-top: 1px;
  }
}

.fuze-more-menu__toggle {
  align-items: $fuze-more-menu-toggle-align-items;
  display: $fuze-more-menu-toggle-display;
  justify-content: $fuze-more-menu-toggle-justify-content;
  padding: $fuze-more-menu-toggle-padding;
  width: $fuze-more-menu-toggle-width;
}

.fuze-more-menu__toggle-icon {
  bottom: $fuze-more-menu-toggle-icon-bottom;
}
