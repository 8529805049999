.devices__header {
  margin: 0 auto 3.3rem;
  text-align: center;
  font-weight: $fuze-font-weight-light;
  font-size: $fuze-font-size-statistic-title;
  letter-spacing: 1px;
  color: white;
}

.devices__macaddress-header {
  font-weight: $fuze-font-weight-light;
  margin-bottom: 1rem;
}
