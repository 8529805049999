.formik-wrapper {
  align-items: baseline;
  display: grid;
  grid-template-columns: 1fr 3ch;
  grid-gap: 0.5ch;

  .fuze-button {
    width: 100%;
    display: block;
  }
}
