.fieldset-terms-of-use {
  position: relative;

  &::before {
    content: url("../../images/Fuzebot_reg.png");
    position: absolute;
    left: 0.75rem;
    top: 3.75rem;
    z-index: 0;
  }
}

#terms-of-use {
  background-color: #7b59b9;
  border-radius: 4px;
  font-size: $font-size-sm;
  line-height: 1.4;
  margin: 2rem auto;
  max-width: 540px;
  padding: 1.5rem 2rem 0.5rem;
  position: relative;
  text-transform: uppercase;

  .terms-text {
    p,
    .p {
      color: rgba(white, 0.8);
    }
    a,
    .dropdown-admins__button {
      @extend .a;

      font-weight: $font-weight-bold;
      text-decoration: underline;
      text-transform: uppercase;
      background-color: transparent;
      border: 0 none;
      padding: 0;
    }
  }

  .buttons {
    text-align: center;

    .term-button#{$fuze-bootstrap-button-selector} {
      display: table;
      margin: 2rem auto 1rem;
    }

    .term-button--do-not-accept {
      border: 0 none;
      color: #fff !important;
      background-color: transparent;
      opacity: 0.8;
    }
  }
}
