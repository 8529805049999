.user-profile-header__title--light {
  color: $gray-50;
  font-style: italic;
  margin: 0 0.25em;
}

.user-profile-header__plan {
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    linear-gradient(45deg, $purple-extra-light, $teal-light) !important;
  background-origin: border-box !important;
  border: 1px solid transparent !important;
  box-shadow: inset 0 0 0 2em white;
  color: inherit !important;
  display: flex;
  margin-bottom: 1em;
  text-transform: initial !important;
  letter-spacing: 0 !important;
}

.user-profile-header__plan-icon {
  height: 1.25em;
  margin-left: 1ch;
}

.user-profile-header__plan-display {
  @extend .btn;

  cursor: initial !important;
}

.user-profile-header__plan-btn {
  display: inline-flex;
  [class^="icon"] {
    bottom: initial;
    margin-left: 0.25em;
  }
  &:hover {
    box-shadow: inset 0 0 0 2em $gray-10;
  }
  &:focus {
    box-shadow: inset 0 0 0 2em $gray-10, $btn-focus-box-shadow !important;
  }
  &:disabled,
  &.user-profile-header__plan-btn--disabled {
    opacity: 1;
    display: inline-block;
    pointer-events: none;
  }

  .plan-btn__label {
    margin-left: 0.75em;
  }
}

.user-profile-header__plan-btn--no-service {
  box-shadow: inset 0 0 0 2em transparent;
  color: white !important;
  text-transform: initial !important;
  &:hover {
    box-shadow: inset 0 0 0 2em rgba(black, 0.25);
  }
  &:focus {
    box-shadow: inset 0 0 0 2em rgba(black, 0.25), $btn-focus-box-shadow !important;
  }
}

.user-profile-header__lock {
  font-size: $fuze-font-size-h3;
  padding-right: 0.25em;

  &:before {
    color: white;
  }
}
