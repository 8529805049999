.user-profile__no-service {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 8vh 0 0;

  strong {
    font-style: italic;
  }
}

.tab-panel {
  max-width: inherit;
}

.grid--user-profile {
  &.user-profile__tab-panel--ratings {
    @include media-breakpoint-down(lg) {
      @include single-column-layout;
    }
  }

  //  TODO: Make this global
  .phone__macAddress {
    font-size: $fuze-font-size-small;
    font-weight: $fuze-font-weight-regular;
    color: $gray-70;
  }
}

.user-profile__data-below-threshold {
  padding: 3rem 0;
  position: relative;

  .grid--user-profile {
    &:after {
      background: linear-gradient(0deg, rgba(248, 248, 248, 1) 25%, rgba(248, 248, 248, 0) 70%);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.panel--fake {
  height: 300px;
  overflow: hidden;

  .panel__inset {
    opacity: 0.5;
  }

  svg {
    height: auto;
    width: 100%;
  }
}

.data-below-threshold__layer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  .data-below-threshold__title {
    padding-top: 10rem;
  }
}

.panel__not-enough-data-container {
  align-items: center;
  display: flex;
  font-style: italic;
  font-weight: 500;
  justify-content: center;
  height: 100%;
  text-align: center;
  width: 100%;
}

// Style for child view
// Importing this at the end so that the selectors can inherit
// view variables if needed
@import "./components/EventsLog/EventsLog";
