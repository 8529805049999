.section-gradient {
  -ms-flex: 1 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 3rem 2rem 0;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 10;
    box-shadow: inset 0 -4px 2px -2px rgba(black, 0.1);
  }

  & > *:not(.section-gradient__card):not(button) {
    color: white;
  }
}

.gradient-section__title {
  font-size: $fuze-font-size-statistic-title;
  margin-bottom: 2.8rem;
  font-weight: $fuze-font-weight-light;
  color: white;
}

.section-gradient--purple-blue {
  @include gradient-purple-purple-to-blue();
}

.section-gradient--purple {
  @include gradient-purple-to-extra-light-purple();
}

.section-gradient--warning {
  @include fuze-error-gradient();
}

.section-gradient--gray {
  background: $gray-20;
}

.section-gradient__card {
  position: relative;
  padding: 2rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px 6px 0 0;
  background: white;
  text-align: center;
  box-shadow: -5px 3px 10px 10px rgba(black, 0.1);
  width: 100%;
  @include media-breakpoint-up(md) {
    padding: 2.8rem 5rem;
  }
}

.section-gradient__card--small {
  max-width: 40em;
  box-shadow: 0 0 6px 2px rgba(black, 0.25);
}

.section-gradient__card--medium {
  max-width: 53.333em;
}

.section-gradient__card--gray {
  background-color: $gray-3;
}

.section-gradient__back-button {
  $box-shadow: -5px 3px 10px 10px rgba(black, 0.1);
  align-self: flex-start;
  position: relative;
  display: inline-block;
  padding: 0.75rem;
  margin-bottom: 2rem;
  border-radius: 6px;
  border: none;
  background: white;
  text-transform: uppercase;
  color: $gray-80;
  font-size: $fuze-font-size-small;
  letter-spacing: 1px;
  font-weight: 400;
  box-shadow: $box-shadow;
  transition: 0.3s;
  &:focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow, $box-shadow;
  }
  &:focus,
  &:active,
  &:hover {
    background-color: $primary;
    color: white;
    i:before {
      color: $primary;
    }
  }
  i {
    display: inline-block;
    font-size: $font-size-xxs;
    bottom: initial;
    margin-right: 0.4rem;
  }
}

.section-gradient__title {
  font-weight: $fuze-font-weight-light;
  margin-bottom: 0.75em;
  .user-detail {
    padding: 0.25em 0.5em;
    font-weight: $fuze-font-weight-light;
  }
}

.user-detail-button__edit-icon {
  color: $gray-40;
  height: 0.75em;
}

.section-gradient__avatar {
  position: relative;
  top: -1.5em;
  margin: auto auto -1em !important;

  .fuze-avatar__overlay--image:after {
    @include custom-gradient();

    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.58;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.section-gradient__menu {
  position: absolute;
  top: 1rem;
  right: 0;
}

.section-gradient__horizontal-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 0.5em;
  word-break: break-word;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  [fuze-icon] {
    height: 0.9em;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:disabled {
    color: initial;
  }
}

.section-gradient__horizontal-list-item {
  font-size: $fuze-font-size-regular;
  margin: 0 0.25em;
  white-space: nowrap;
  height: 2.666em;
  display: inline-flex;
  align-items: center;

  & + .section-gradient__horizontal-list-item {
    &:before {
      @include media-breakpoint-up(lg) {
        background: black;
        border: 1px solid transparent;
        border-radius: 100%;
        content: "";
        height: 4px;
        display: inline-block;
        width: 4px;
      }
      margin: 0 1ch 0 0.35ch;
      font-size: $fuze-font-size-h4;
      line-height: initial;
    }
  }
}

.section-gradient__horizontal-list-label {
  &:after {
    content: ":";
    margin-right: 0.5ch;
  }
}
