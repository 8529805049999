.panel__inset--bulk-replace {
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
  grid-gap: 1.125rem;
}

.bulk-uploaded-file {
  word-break: break-word;
}

.bulk-uploaded-file__name,
.bulk-matched-file__name {
  display: block;
  line-height: $h3-font-size;
}

.bulk-uploaded-file__label,
.bulk-matched-file__pbx {
  display: block;
  font-size: $fuze-font-size-xsmall;
  line-height: $fuze-font-size-regular;
}

.bulk-matched-files {
  padding-left: 0;
}

.bulk-matched-file {
  display: flex;
  justify-content: space-between;
  margin-bottom: $fuze-font-size-xsmall;

  &:last-child {
    margin-bottom: 0;
  }
}

.bulk-matched-file__remove {
  color: $gray-700;
  &:hover {
    color: $gray-990;
  }
}

.no-match-message {
  .error {
    display: inline-block;

    .form-field-validation__icon {
      width: 1em;
    }
  }
}
